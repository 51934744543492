<template>
  <div class="card card-full card-s3">
<!--    <div class="card-author d-flex align-items-center justify-content-between pb-3">-->
<!--      <div class="d-flex align-items-center">-->
<!--        &lt;!&ndash;        <router-link :to="product.presignedUrl" class="avatar me-1">&ndash;&gt;-->
<!--        <img class="avatar me-1" src="@/images/tmp/system1.png" alt="logo">-->
<!--        &lt;!&ndash;        </router-link>&ndash;&gt;-->
<!--        <span class="custom-tooltip-wrap card-author-by-wrap">-->
<!--          <span class="card-author-by card-author-by-2">Launched Volume</span>-->
<!--        <div class="progress-box">-->
<!--          &lt;!&ndash;            <b-progress :max="100" animated>&ndash;&gt;-->
<!--          &lt;!&ndash;              <b-progress-bar :value="product.progress" :variant="product.progress === 100 ? 'danger' : ''">&ndash;&gt;-->
<!--          &lt;!&ndash;                <span><strong>{{ product.progress }}%</strong></span>&ndash;&gt;-->
<!--          &lt;!&ndash;              </b-progress-bar>&ndash;&gt;-->
<!--          &lt;!&ndash;            </b-progress>&ndash;&gt;-->
<!--          <div class="progress" role="progressbar" aria-label="Animated striped example"-->
<!--               :aria-valuenow="product.progress" aria-valuemin="0" aria-valuemax="100">-->
<!--            <div class="progress-bar progress-bar-striped progress-bar-animated"-->
<!--                 :class="[product.progress === 100 ? 'bar-color_over' : 'bar-color_proceed']"-->
<!--                 :style="{width: product.progress + '%'}">{{ product.progress }}%</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        </span>-->
<!--      </div>-->
<!--    </div>&lt;!&ndash; end card-author &ndash;&gt;-->
    <div class="card-image">
      <img :src="product.presignedUrl" class="card-img" alt="art image">
      <!--      <img :src="require('@/images/tmp/test02.png')" class="card-img" alt="art image">-->
    </div>
    <div class="card-body px-0 pb-0">
      <h4 class="card-title text-truncate">{{ product.name }}</h4>
      <div class="card-price-wrap d-flex align-items-center justify-content-sm-between pb-3">
        <div class="me-5 me-sm-2">
          <span class="card-price-title">Price</span>
          <span class="card-price-number d-flex">&dollar;{{ product.amount }}</span>
        </div>
        <div class="text-sm-end">
          <span class="card-price-title">Contract Term</span>
          <span class="card-price-number d-block"> {{ getDate(product.period) }}</span>
        </div>


      </div><!-- end card-price-wrap -->
      <div class="card-price-wrap d-flex align-items-center justify-content-sm-between pb-3">
        <div class="me-5 me-sm-2">
          <span class="card-price-title">Daily Rewards</span>
          <div class="card-price-number"> ${{ (product.amount * product.percent).toFixed(2) }}</div>
        </div>
        <div class="text-sm-end">
          <span class="card-price-title">Capital Back</span>
          <span class="card-price-number d-block"> Yes </span>
        </div>
      </div>

      <div class="card-price-wrap d-flex align-items-center justify-content-sm-between pb-3">
        <div class="me-5 me-sm-2">
          <span class="card-price-title">{{ product.period }} Days Fixed Rewards</span>
          <div class="card-price-number"> ${{ product.amount }} +
            ${{ (product.amount * product.percent * product.period).toFixed(2) }}
          </div>
        </div>
        <!--        <div class="text-sm-end">-->
        <!--          <span class="card-price-title">Capital Back</span>-->
        <!--          <span class="card-price-number d-block"> Yes </span>-->
        <!--        </div>-->
      </div>

      <div class="d-flex align-items-center">
        <!--        <router-link :to="product.presignedUrl" class="avatar me-1">-->
        <img class="avatar me-1" src="@/images/tmp/system1.png" alt="logo">
        <!--        </router-link>-->
        <span class="custom-tooltip-wrap card-author-by-wrap" style="width: 100px; overflow: hidden;">
<!--          <span class="card-author-by card-author-by-2">Launched Volume</span>-->
          <span class="card-author-by card-author-by-2" style="white-space: nowrap;">Sold </span>
        <div class="progress-box">
          <!--            <b-progress :max="100" animated>-->
          <!--              <b-progress-bar :value="product.progress" :variant="product.progress === 100 ? 'danger' : ''">-->
          <!--                <span><strong>{{ product.progress }}%</strong></span>-->
          <!--              </b-progress-bar>-->
          <!--            </b-progress>-->
          <div class="progress" role="progressbar" aria-label="Animated striped example"
               :aria-valuenow="product.progress" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar progress-bar-striped progress-bar-animated"
                 :class="[product.progress === 100 ? 'bar-color_over' : 'bar-color_proceed']"
                 :style="{width: product.progress + '%'}">{{ product.progress }}%</div>

          </div>

        </div>


        </span>
        <button href="#" @click="seeMoreClick" class="btn btn-grad btn-sm mt-3" style="margin-left: 2rem;">Buy Now</button>
      </div>

      <!--      <span class="btn btn-sm btn-danger" :class="[seeMore, ]">Invest Now</span>-->
<!--      <button href="#" @click="seeMoreClick" class="btn btn-grad btn-sm" style="margin-left: 0.375rem;">Start Mining-->
<!--      </button>-->
    </div><!-- end card-body -->
  </div><!-- end card -->
</template>
<script>
import date from "@/date";

export default {
  name: 'ProductsSix',
  props: ['product', 'index', 'bitCoin', 'isRelated'],
  data() {
    return {
      seeMore: 'btn-outline-dark',
    }
  },
  methods: {
    getDate(data) {
      return date.getDate(data);
    },
    seeMoreClick() {
      window.location.href = `/product-details?id=${this.product.id}`
    },
  }
}
</script>

<style lang="css" scoped>
.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.bar-color_proceed {
  background-color: #7952b3 !important;
}

.bar-color_over {
  background-color: #DD1144 !important;
}


.author-link,
.card-action,
.card-price-wrap {
  z-index: 2;
  position: relative;
}

.card-author .card-author-by-2 {
  font-size: 0.8rem;
}

.card-s3 .card-price-wrap .card-price-title {
  font-size: 0.95rem;
}

.card-s3 .card-price-wrap .card-price-number {
  font-size: 1.2rem;
}

@media (max-width: 575px) {
  .text-truncate {
    font-size: 0.8rem;
  }

  .card-s3 .card-price-wrap .card-price-title {
    font-size: 0.8rem;
  }

}

@media (min-width: 992px) {
  .text-truncate {
    font-size: 1rem;
  }

  .card-s3 .card-price-wrap .card-price-title {
    font-size: 1rem;
  }

}

</style>
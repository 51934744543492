<template>
  <div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
    </header>
    <section class="item-detail-section section-space" v-loading="loading">
      <div class="container">
        <div class="row">
          <div class="el-col-24 el-col-md-11">
            <div class="item-detail-content">
              <div class="item-detail-img-container">
                <div class="sell_out" v-if="product.progress === 100">
                  <img src="@/images/tmp/sold-out1.png" alt="">
                </div>
                <!--                <img :src="product.presignedUrl" class="product-img-top w-100 rounded-3"  v-if="ImageData[index]" :alt="ImageData[index].alt" :title="ImageData[index].title">-->
                <img :src="product.presignedUrl" class="product-img-top w-100 rounded-3" alt="" title="">
                <!--                <img :src="imgLg" alt="" class="w-100 rounded-3">-->
              </div><!-- end item-detail-img-container -->
            </div><!-- end item-detail-content -->
          </div><!-- end col -->
          <div class="el-col-24 el-col-md-12">
            <div class="item-detail-content mt-4 mt-lg-0">
              <h1 class="item-detail-title ">{{ product.name }}</h1>
              <br/>
              <!--              <h6 class="item-detail-title_sub  ">${{ product.amount }} / {{   getDate(product.period) }}</h6>-->
              <div class="item-detail-meta mb-3">
                <div class="row g-0">
                  <div class="el-col-10 value-item">
                    <div class="label">Contract Term</div>
                    <div class="value">{{ getDate(product.period) }}</div>
                  </div>
                  <div class="el-col-10 value-item">
                    <div class="label">Contract Price</div>
                    <div class="value">$ {{ product.amount }}</div>
                  </div>
                  <div class="el-col-10 value-item">
                    <div class="label">Daily Rewards</div>
                    <div class="value">${{ (product.amount * product.percent).toFixed(2) }}</div>
                  </div>

                  <template v-if="product.limitTime>1">
                    <div class="col-12 value-item" v-if="product.showType===1">
                      <div class="label">Monthly revenue:</div>
                      <div class="value">{{ ((product.amount * product.percent * 30) / bitCoin).toFixed(8) }} BTC ( ≈ $
                        {{ (product.amount * product.percent * 30).toFixed(2) }} )
                      </div>
                    </div>

                    <div class="col-12 value-item" v-if="product.showType===2">
                      <div class="label">{{ product.period }} Days Fixed Return:</div>
                      <div class="value">${{ product.amount }} +
                        ${{ (product.amount * product.percent * product.period).toFixed(2) }}
                      </div>
                    </div>
                  </template>
                  <div class="col-12 value-item" v-if="product.showType===3">
                    <div class="label">1 Day Fixed Rewards:</div>
                    <div class="value">${{ product.amount }} +
                      ${{ (product.amount * product.percent * product.period).toFixed(2) }} + $5(Bonus)
                    </div>
                  </div>


                  <div class="col-12">
                    <div class="col-12 progress-wrap">
                      <div class="left"> Volume</div>
                      <div class="right">
                        <div class="progress-box">

                          <div class="progress" role="progressbar" aria-label="Animated striped example"
                               :aria-valuenow="product.progress" aria-valuemin="0" aria-valuemax="100">
                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                 :class="[product.progress === 100 ? 'bar-color_over' : 'bar-color_proceed']"
                                 :style="{width: product.progress + '%'}">{{ product.progress }}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mt-4" v-if="product.progress === 100" style="text-align: center">
                      <!--                      <img src="@/images/sell_out_icon.png" alt="">-->
                    </div>
                    <div v-else class="item-detail-btns mt-5">
                      <ul class="btns-group d-flex ">
                        <li class="flex-grow-1" style="max-width: 300px;">
                          <a href="#" @click="buyNow" class="btn btn-grad d-block">Buy Now</a>
                        </li>
                        <!--                        <li class="flex-grow-1">-->
                        <!--                          <a href="#" class="btn bg-dark-dim d-block" >See More</a>-->
                        <!--                        </li>-->
                      </ul>
                    </div><!-- end item-detail-btns -->
                  </div>
                </div>
              </div>
            </div><!-- end item-detail-content -->
          </div><!-- end col -->
        </div><!-- end row -->
        <div class="row mt-4">
          <div class="item-detail-tab">
            <ul class="nav nav-tabs nav-tabs-s1" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" data-bs-toggle="tab" type="button">Detail</button>
              </li>
            </ul>
            <div class="tab-content mt-3" id="myTabContent">
              <div class="tab-pane fade show active" id="detail" role="tabpanel" aria-labelledby="detail-tab">
                <div class="item-detail-tab-wrap">
                  <div v-html="product.longDesc"></div>
                </div><!-- end item-detail-tab-wrap -->
              </div><!-- end tab-pane -->
            </div>
          </div>
        </div>
      </div><!-- .container -->
    </section><!-- end item-detail-section -->
    <!-- Related product -->
    <RelatedProduct class="pt-5"></RelatedProduct>
    <!-- Footer  -->
    <Footer classname="bg-cus on-dark"></Footer>
    <!-- Modal -->
    <div class="modal fade" id="placeBidModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{{ SectionData.placeBidModal.title }}</h4>
            <button type="button" class="btn-close icon-btn" data-bs-dismiss="modal" aria-label="Close">
              <em class="ni ni-cross"></em>
            </button>
          </div><!-- end modal-header -->
          <div class="modal-body">
            <p class="mb-3" v-html="SectionData.placeBidModal.content"></p>
            <div class="mb-3">
              <label class="form-label">{{ SectionData.placeBidModal.labelText }}</label>
              <input type="text" class="form-control form-control-s1" placeholder="Enter bid">
            </div>
            <div class="mb-3">
              <label class="form-label" v-html="SectionData.placeBidModal.labelTextTwo"></label>
              <input type="text" class="form-control form-control-s1" value="1">
            </div>
            <ul class="total-bid-list mb-4">
              <li v-for="(list, i) in SectionData.placeBidModal.totalBidList" :key="i"><span>{{ list.title }}</span>
                <span>{{ list.price }}</span></li>
            </ul>
            <a :href="SectionData.placeBidModal.btnLink"
               class="btn btn-dark d-block">{{ SectionData.placeBidModal.btnText }}</a>
          </div><!-- end modal-body -->
        </div><!-- end modal-content -->
      </div><!-- end modal-dialog -->
    </div><!-- end modal-->
    <BuyNow :plan="PlanData" :showBuyNow="showBuyNow" @buyNowModal="buyNowModal"></BuyNow>
  </div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
// import { mapGetters } from "vuex";
import date from "@/date";
// import indexApi from "@/apis/indexApi";
import planApi from "@/apis/planApi";
import BuyNow from "@/components/n1/BuyNow";
import storage from "@/storage";
import Footer from "@/pages/n1/Footer"
import RelatedProduct from "@/components/n1/RelatedProduct"

export default {
  name: 'ProductDetail',
  components: {BuyNow,Footer,RelatedProduct},
  data() {
    return {
      SectionData,
      id: this.$route.query.id,
      title: '',
      imgLg: '',
      metaText: '',
      metaTextTwo: '',
      metaTextThree: '',
      content: '',

      bitCoin: '1',
      product: {},
      loading: true,
      PlanData: undefined,
      Show: false,
      showBuyNow: false,
    }
  },
  computed: {
    // ...mapGetters(['product'])
  },
  created() {
    // console.log(this.$route)
    // eslint-disable-next-line no-undef
    // indexApi.GetBitcoin('', res => {
    //       // alert(res.data)
    //       this.bitCoin = res.data
    //     }
    // )
  },
  mounted() {
    // console.log(this.product)
    this.getDetail()
  },
  methods: {
    buyNow() {
      let userId = storage.localGet("userId")
      if (!userId) {
        this.$router.push("/sign-in")
      } else {
        this.showBuyNow = true
      }

    },
    getDate(data) {
      return date.getDate(data);
    },
    getDetail() {
      this.loading = true;
      planApi.detail({planId: this.id}, res => {
        // console.log(res)
        this.loading = false;
        this.product = res.data
        this.PlanData = this.product
      })
    },
    buyNowModal() {
      console.log('child showBuyNow:' + this.showBuyNow)
      this.showBuyNow = false
      console.log('showBuyNow change:' + this.showBuyNow)
    },
  }
}
</script>

<style scoped lang="scss">

.bg-cus {
  background-color: #ffe5d0;
}

.section-space {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5px;
  padding-right: 5px;
}

.item-detail-section {
  padding-top: 20px;
}

@media (min-width: 768px) {
  .item-detail-section {
    padding-top: 20px;
  }
}


.item-detail-img-container {
  position: relative;
}

.sell_out {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2; /* Place the overlay above the image */
}

.sell_out img {
  height: 8rem;
  z-index: 3; /* Place the "soldout" image above the overlay */
}


.item-detail-meta {
  .value-item {
    margin-bottom: 0.625rem;

    .label {
      font-size: 1rem;
      font-weight: 400;
      color: #8492A6;
      line-height: 2rem;
    }

    .value {
      font-size: 1.1rem;
      font-weight: 600;
      color: #273444;
      line-height: 2rem;
    }
  }

  .progress-wrap {
    display: flex;
    align-items: center;
    margin-top: 0.625rem;

    .left {
      //height: 14px;
      font-size: 0.9rem;
      //font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8492A6;
      line-height: 0.8rem;
    }

    .right {
      flex: 1;
      width: 0;
      margin-left: 0.875rem;

      .progress-box {
        height: 1.1rem;
        position: relative;
        overflow: hidden;

        .progress {
          height: 100%;
          background: #E9ECEF;
        }

      }
    }

  }

  .item-detail-btns {
    padding: 0.5rem 0.3rem;
    font-size: 0.825rem;
  }
}

@media screen and (min-width: 600px) {
  .sell_out img {
    height: 10rem;
  }
}


::v-deep .bar-color {
  &_proceed {
    background-color: #7952b3 !important;
  }

  &_over {
    background-color: #DD1144 !important;
  }
}
</style>
